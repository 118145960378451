import React from "react";
import Header from "../Components/Header";
import MainContainer from "../Components/MainContainer";
import TextInput from "../Components/TextInput";
import SelectInput from "../Components/SelectInput";
import data from "../Utils/Data";
import { ApiInstance, ApiRoutes } from "../Utils/ApiInstance";
import Modal from "../Components/Modal/Modal";
import ErrorModal from "../Components/Modal/ErrorModal";
import { PhoneField } from "../Components/PhoneField";
import { isHasValue } from "../Utils/Helpers";

function Home() {
  const phoneRef = React.useRef(null);
  const [idType, setIdType] = React.useState(data.id_types[0]);
  const [id, setId] = React.useState("");
  // const [businessRegNo, setBusinessRegNo] = React.useState("");
  // const [nric, setNric] = React.useState("");
  // const [passportNo, setPassportNo] = React.useState("");
  // const [armyNo, setArmyNo] = React.useState("");
  const [taxNo, setTaxNo] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [confirmEmail, setConfirmEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [businessRegNoOld, setBusinessRegNoOld] = React.useState("");
  const [msic, setMsic] = React.useState(data.msics[0]);
  const [sstNo, setSstNo] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  // const [address3, setAddress3] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState(data.states[0]);
  const [country, setCountry] = React.useState(data.countries[0]);
  const [invNo, setInvNo] = React.useState("");
  const [invAmount, setInvAmount] = React.useState("MYR 0.00");
  const [errId, setErrId] = React.useState("");
  const [errTaxNo, setErrTaxNo] = React.useState("");
  const [errName, setErrName] = React.useState("");
  const [errEmail, setErrEmail] = React.useState("");
  const [errConfirmEmail, setErrConfirmEmail] = React.useState("");
  const [errPhone, setErrPhone] = React.useState("");
  const [errMsic, setErrMsic] = React.useState("");
  const [errAddress1, setErrAddress1] = React.useState("");
  const [errPostcode, setErrPostcode] = React.useState("");
  const [errCity, setErrCity] = React.useState("");
  const [errState, setErrState] = React.useState("");
  const [errCountry, setErrCountry] = React.useState("");
  const [errInvNo, setErrInvNo] = React.useState("");
  const [errInvAmount, setErrInvAmount] = React.useState("");

  const [isAgreeTnc, setIsAgreeTnc] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [modalCode, setModalCode] = React.useState(0);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleCloseModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    setModalCode(0);
    // Reset error modal
    setErrorMessage("");
    if (isSuccess) window.location.reload();
  };

  const generateModalContent = () => {
    // 0: default/none
    // 1: success
    // 2: error
    // 3:
    if (modalCode === 1) {
      return (
        <ErrorModal
          title="Success"
          description="Your request has been successfully submitted."
        />
      );
    } else if (modalCode === 2) {
      return <ErrorModal description={errorMessage} />;
    } else if (modalCode === 3) {
      return (
        <div className="x-form-col">
          <div className="x-dialog-title for-action">Sample Format for BRN</div>
          <div>
            <div className="x-dialog-row grid">
              <div>Company name</div>
              <div>ABC SDN. BHD.</div>
            </div>
            <div className="x-dialog-row grid">
              <div>Registration No.</div>
              <div style={{ gridColumn: "span 2 / span 2" }}>
                <span className="text-red-500" style={{ color: "#e00069" }}>
                  100000123456
                </span>{" "}
                &nbsp; <span className="text-green-500">(123456-A)</span>
              </div>
            </div>
            <div className="x-dialog-row grid">
              <div>New format</div>
              <div style={{ color: "#e00069" }}>100000123456</div>
            </div>
            <div className="x-dialog-row grid">
              <div>Old format</div>
              <div>(123456-A)</div>
            </div>
            <div className="x-dialog-row">
              <p>To search for BRN information</p>
              <ul>
                <li>
                  {" "}
                  Peninsular Malaysia, click{" "}
                  <a
                    href="https://www.ssm.com.my/Pages/e-Search.aspx"
                    target="_blank"
                  >
                    {" "}
                    HERE{" "}
                  </a>
                </li>
                <li>
                  {" "}
                  Sarawak (Enterprise), click{" "}
                  <a
                    href="https://spnp.hasil.gov.my/CarianNamaPerniagaan.aspx"
                    target="_blank"
                  >
                    {" "}
                    HERE{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  };

  const getIdTypeList = () => {
    return data.id_types.map((item, key) => (
      <option key={key} value={item.value}>
        {item.label}
      </option>
    ));
  };

  const getIdFieldTitle = () => {
    switch (idType.value) {
      case "BUSINESS REGISTRATION NO. (BRN)":
        return "Business Registration No. (New)";
      case "NRIC":
        return "NRIC";
      case "PASSPORT":
        return "Passport No.";
      case "ARMY":
        return "Army No.";
    }
  };

  // const getIdFieldId = () => {
  //   switch (idType) {
  //     case "BRN":
  //       return "business_registration_no_new";
  //     case "NRIC":
  //       return "nric";
  //     case "PASSPORT":
  //       return "passport_no";
  //     case "ARMY":
  //       return "army_no";
  //   }
  // };

  const handleInvAmountChange = (e) => {
    let inputValue = e.target.value;

    // Remove any characters that are not digits (except the 'MYR' part and the decimal point)
    if (inputValue.startsWith("MYR")) {
      inputValue = inputValue.replace(/^MYR\s*/, ""); // Remove 'MYR ' prefix
    }

    // Remove any non-numeric characters, but allow for decimal points
    inputValue = inputValue.replace(/[^\d]/g, "");

    // Convert the string to a number, making sure it falls within a valid range
    let numericValue = parseInt(inputValue, 10) || 0;

    // Format the number to two decimal places
    const formattedValue = `MYR ${(numericValue / 100).toFixed(2)}`;

    setInvAmount(formattedValue);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    clearErrors();

    setIsSubmitting(true);
    let isValidated = true;

    let countryCode = phoneRef.current?.state?.country?.dialCode;
    let phoneNumber = phone.replace(`+${countryCode}`, "");
    let numericInvAmount = parseFloat(invAmount.replace(/^MYR\s*/, "")) || 0;

    if (!isHasValue(id)) {
      setErrId("This field is required");
      isValidated = false;
    }
    if (idType?.value === "NRIC" && (id.length < 12 || id.length > 12)) {
      setErrId("Invalid NRIC");
      isValidated = false;
    }
    if (!isHasValue(taxNo)) {
      setErrTaxNo("This field is required");
      isValidated = false;
    }
    if (!isHasValue(name)) {
      setErrName("This field is required");
      isValidated = false;
    }
    if (!isHasValue(email)) {
      setErrEmail("This field is required");
      isValidated = false;
    }
    if (isHasValue(email) && !validateEmail(email)) {
      setErrEmail("Invalid email");
      isValidated = false;
    }
    if (!isHasValue(confirmEmail)) {
      setErrConfirmEmail("This field is required");
      isValidated = false;
    }
    if (
      isHasValue(email) &&
      isHasValue(confirmEmail) &&
      email !== confirmEmail
    ) {
      setErrConfirmEmail("Email does not match");
      isValidated = false;
    }
    if (!isHasValue(phoneNumber)) {
      setErrPhone("This field is required");
      isValidated = false;
    }
    if (!isHasValue(msic.value)) {
      setErrMsic("This field is required");
      isValidated = false;
    }
    if (!isHasValue(address1)) {
      setErrAddress1("This field is required");
      isValidated = false;
    }
    if (!isHasValue(postcode)) {
      setErrPostcode("This field is required");
      isValidated = false;
    }
    if (postcode.length < 5 || postcode.length > 5) {
      setErrPostcode("Invalid postcode");
      isValidated = false;
    }
    if (!isHasValue(city)) {
      setErrCity("This field is required");
      isValidated = false;
    }
    if (!isHasValue(state.value)) {
      setErrState("This field is required");
      isValidated = false;
    }
    if (!isHasValue(country.value)) {
      setErrCountry("This field is required");
      isValidated = false;
    }
    if (!isHasValue(invNo)) {
      setErrInvNo("This field is required");
      isValidated = false;
    }
    if (!isHasValue(invAmount)) {
      setErrInvAmount("This field is required");
      isValidated = false;
    }
    if (isHasValue(invAmount) && numericInvAmount <= 0) {
      setErrInvAmount("Receipt amount must be greater than MYR 0.00");
      isValidated = false;
    }

    if (!isValidated) {
      setIsSubmitting(false);
      return;
    }

    submitForm();
  };

  const submitForm = async () => {
    let body = {
      id_type: idType.value,
      tax_identification_no: taxNo,
      personal_company_name: name,
      email: email,
      confirm_email: confirmEmail,
      phone: phone,
      business_registration_no_old: businessRegNoOld,
      misc_code: msic.value,
      sst_no: sstNo,
      address_line_1: address1,
      address_line_2: address2,
      // address_line_3: ,
      postcode: postcode,
      city: city,
      state: state.value,
      country: country.value,
      invoice_no: invNo,
      invoice_amount: invAmount,
    };

    if (idType.value === "BUSINESS REGISTRATION NO. (BRN)") {
      body["business_registration_no_new"] = id;
    } else if (idType.value === "NRIC") {
      body["nric"] = id;
    } else if (idType.value === "PASSPORT") {
      body["passport_no"] = id;
    } else if (idType.value === "ARMY") {
      body["army_no"] = id;
    }

    try {
      const response = await ApiInstance.post(ApiRoutes.POST_INVOICE, body);
      setIsSuccess(true);
      setModalCode(1);
      setShowModal(true);
    } catch (error) {
      if (error.response?.data?.message != undefined) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
      setModalCode(2);
      setShowModal(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearErrors = () => {
    setErrId("");
    setErrTaxNo("");
    setErrName("");
    setErrEmail("");
    setErrConfirmEmail("");
    setErrPhone("");
    setErrMsic("");
    setErrAddress1("");
    setErrPostcode("");
    setErrCity("");
    setErrState("");
    setErrCountry("");
    setErrInvNo("");
    setErrInvAmount("");
  };

  return (
    <MainContainer title={"Account"}>
      <div className="x-master">
        <div className="x-master-wrapper">
          <Header />
          <main className="x-main for-subpage">
            <div className="x-settings-wrapper">
              <div className="x-form-wrapper w-form">
                <form>
                  <div className="x-form-body">
                    <div className="x-form-container">
                      <h2>Generate e-Invoice</h2>
                      <div className="x-form-row">
                        <SelectInput
                          className="for-med"
                          title="ID Type"
                          id="id_type"
                          defaultValue={data.id_types[0]}
                          onChange={setIdType}
                          list={data.id_types}
                          required
                        />
                        <TextInput
                          className="for-med"
                          title={getIdFieldTitle()}
                          id="id"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                          error={errId}
                          required
                          showMoreInfo={
                            idType?.value === "BUSINESS REGISTRATION NO. (BRN)"
                          }
                          onClickMoreInfo={(e) => {
                            e.preventDefault();
                            setModalCode(3);
                            setShowModal(true);
                          }}
                          maxLength={idType?.value === "NRIC" ? 12 : 50}
                        />
                      </div>
                      <div className="x-form-row">
                        <TextInput
                          className="for-med"
                          title="Tax Identification No."
                          id="tax_identification_no"
                          value={taxNo}
                          onChange={(e) => setTaxNo(e.target.value)}
                          error={errTaxNo}
                          required
                        />
                        {/* Validate Button */}
                        {/* <div className="x-form-action">
                        <div className="x-btn-container">
                        <a
                            onClick={(e) => handleValidate(e)}
                            className="x-btn w-button"
                        >
                            Validate
                        </a>
                        </div>
                        </div> */}
                      </div>

                      <div className="x-hr-sep"></div>

                      <div className="x-form-row">
                        <TextInput
                          className="for-med"
                          title="Personal / Company Name"
                          id="personal_company_name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          error={errName}
                          required
                        />
                        <PhoneField
                          inputRef={phoneRef}
                          className="for-med"
                          title="Phone"
                          id="phone"
                          value={phone}
                          setValue={setPhone}
                          error={errPhone}
                          required
                        />
                      </div>
                      <div className="x-form-row">
                        <TextInput
                          className="for-med"
                          title="Email"
                          id="email"
                          value={email}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.trim();
                            setEmail(value);
                          }}
                          error={errEmail}
                          required
                        />
                        <TextInput
                          className="for-med"
                          title="Confirm Email"
                          id="confirm_email"
                          value={confirmEmail}
                          onChange={(e) => setConfirmEmail(e.target.value)}
                          error={errConfirmEmail}
                          required
                        />
                      </div>

                      <div className="x-form-row">
                        <TextInput
                          className="for-med"
                          title="Business Registration No. (Old)"
                          id="business_registration_no_old"
                          value={businessRegNoOld}
                          onChange={(e) => setBusinessRegNoOld(e.target.value)}
                        />
                        <SelectInput
                          className="for-med"
                          title="Malaysia Standard Industrial Classification (MSIC) Code"
                          id="misc_code"
                          defaultValue={data.msics[0]}
                          onChange={setMsic}
                          list={data.msics}
                          error={errMsic}
                          required
                        />
                      </div>
                      <div className="x-form-row">
                        <TextInput
                          className="for-med"
                          title="SST No."
                          id="sst_no"
                          value={sstNo}
                          onChange={(e) => setSstNo(e.target.value)}
                        />
                      </div>

                      <div className="x-hr-sep"></div>

                      <div className="x-form-row">
                        <TextInput
                          title="Address Line 1"
                          id="address_line_1"
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          error={errAddress1}
                          required
                        />
                      </div>
                      <div className="x-form-row">
                        <TextInput
                          title="Address Line 2"
                          id="address_line_2"
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                        />
                      </div>
                      <div className="x-form-row">
                        {/* <TextInput
                          title="Address Line 3"
                          id="address_line_3"
                          value={address3}
                          onChange={(e) => setAddress3(e.target.value)}
                        /> */}
                        <TextInput
                          title="City"
                          id="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          error={errCity}
                          required
                        />
                        <TextInput
                          title="Postcode"
                          id="postcode"
                          value={postcode}
                          onChange={(e) => setPostcode(e.target.value)}
                          error={errPostcode}
                          required
                          maxLength={5}
                        />
                      </div>
                      <div className="x-form-row">
                        <SelectInput
                          className="for-med"
                          title="State"
                          id="state"
                          defaultValue={data.states[0]}
                          onChange={setState}
                          list={data.states}
                          error={errState}
                          required
                        />
                        <SelectInput
                          className="for-med"
                          title="Country"
                          id="country"
                          defaultValue={data.countries[0]}
                          onChange={setCountry}
                          list={data.countries}
                          error={errCountry}
                          required
                        />
                      </div>

                      <div className="x-hr-sep"></div>

                      <div className="x-form-row">
                        <TextInput
                          title="Receipt No."
                          id="invoice_no"
                          value={invNo}
                          onChange={(e) => setInvNo(e.target.value)}
                          error={errInvNo}
                          required
                        />
                        <TextInput
                          title="Receipt Amount"
                          id="invoice_amount"
                          value={invAmount}
                          onChange={handleInvAmountChange}
                          error={errInvAmount}
                          required
                        />
                      </div>
                      <div className="x-form-row">
                        <div className="x-form-opt">
                          <label className="w-checkbox x-input-chkbox">
                            <div
                              className={`w-checkbox-input w-checkbox-input--inputType-custom x-input-chkbox-btn ${
                                isAgreeTnc ? "w--redirected-checked" : ""
                              }`}
                            />
                            <input
                              type="checkbox"
                              id="is_default_pickup_address"
                              name="is_default_pickup_address"
                              data-name="is_default_pickup_address"
                              style={{
                                opacity: 0,
                                position: "absolute",
                                zIndex: -1,
                              }}
                              value={isAgreeTnc.toString()}
                              onChange={(e) =>
                                setIsAgreeTnc(
                                  e.target.value === "false" ? true : false
                                )
                              }
                            />
                            <span className="x-input-chkbox-label w-form-label">
                              I agree to the{" "}
                              <a
                                href="https://www.dobiqueen.my/tnc"
                                target="_blank"
                              >
                                Terms and Conditions
                              </a>{" "}
                              and{" "}
                              <a
                                href="https://www.dobiqueen.my/tnc/privacy-policy"
                                target="_blank"
                              >
                                Privacy Policy
                              </a>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="x-form-row">{/* Captcha */}</div>
                      <div className="x-form-action">
                        <div className="x-btn-container">
                          <button
                            className="x-btn w-button"
                            onClick={(e) => handleSubmit(e)}
                            disabled={!isAgreeTnc || isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* MODAL SECTION */}
      <Modal
        showModal={showModal}
        handleCloseModal={(e) => handleCloseModal(e)}
        content={generateModalContent()}
        button={
          <a
            onClick={(e) => handleCloseModal(e)}
            className="x-btn for-secondary is-close-modal w-button"
          >
            Dismiss
          </a>
        }
      />
    </MainContainer>
  );
}

export default Home;
